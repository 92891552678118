import { React, useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PBCHeader from "./PBCHeader";
import CircularProgress from "@mui/material/CircularProgress";
import NoImage from "./assets/NoImage.png";
import { useDispatch } from "react-redux";
import australiaPlaceholder from "./assets/Australia_Flag.png";
import japanPlaceholder from "./assets/Japan_Flag.png";
import thailandPlaceholder from "./assets/Thailand_flag.png";
import uSPlaceholder from "./assets/US_Flag.png";
import { pb_service } from "../../../config/environment";
import { resValidation } from "../../../Utils/CommonUtils";
import NoDataFallback from "../Common/NoDataFallback";
import CategoryCard from "../Common/CategoryCard";
import CommonErrorFallback from "../Common/CommonErrorFallback";
import DisclaimerUtil from "../Common/DisclaimerUtil";
import axios from "axios";
import { encodeUtil } from "../../../Utils/CommonUtils";
import LoadingIndicator from "../Common/LoadingIndicator";
import ResolutionDisclaimer from "../Common/ResolutionDisclaimer";

const Wrapper = styled.div`
  padding: 10rem 5rem 0rem 5rem;
  /* margin-bottom: 2rem; */
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media (max-width: 770px) {
    padding: 10rem 2rem 4rem 2rem;
  }
`;
const CategoryLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7fafc;
  position: relative;
  margin: 1.5rem 0;
`;
const CardGridWrapper = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  flex-direction: row;
  align-content: flex-start;
  align-items: center; */
  
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19%, 1fr)); /* leaving some space for gap */
  gap: 3rem;

  @media (max-width: 770px) {
    grid-template-columns: repeat(auto-fill, minmax(38%, 1fr)); /* leaving some space for gap */
    gap: 3rem;
  }
`;

const pageType = "CountryList";

const PrivateBrandCatalog = (props) => {
  const navigate = useNavigate();
  const [allCountry, setAllCountry] = useState([]);
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);

  const dispatch = useDispatch();


  const fetchCountryList = () => {
    setIsLoadingCountry(true);
    const encodedFilter = encodeUtil([{"field": "tierLevel", "value":"3","operator":"equal"}]);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${pb_service}/api/itemmaster/hierarchycodes/hierarchySearch?noOfRecords=100&pageNo=1&sortBy=createdDatetime&sortDirection=ASC&filters=${encodedFilter}`,
    };
    
    axios.request(config)
    .then((response) => {
      let validatedRes = resValidation(response);
      setAllCountry(validatedRes.data.data.rows);
      setIsLoadingCountry(false);
    })
    .catch((error) => {
      console.error(error);
      setIsLoadingCountry(false);
      setApiFailReported(true);
    });

  };

  useEffect(() => {
    fetchCountryList();
  }, []);
  
  const handleCardClick = (countryName, id, itemIsAdded ) => {
    localStorage.setItem("country_id", id);
    navigate(`/privateBrandCatalog/${countryName}`);
  };

  return (
    <Wrapper>
		{isLoadingCountry && <LoadingIndicator />}
      <PBCHeader pageType={pageType} />
      <DisclaimerUtil></DisclaimerUtil>
      <CategoryLayout>
      {apiFailReported ?
        <CommonErrorFallback/> :
        <>
        {allCountry.length ?
          <CardGridWrapper>
            {allCountry.map((country) => {
              return (
                <CategoryCard
                  imgSrc={country.hierarchyUrl? country.hierarchyUrl: 
                    country.description==="USA"? uSPlaceholder :
                    country.description==="Australia"? australiaPlaceholder :
                    country.description==="Thailand"? thailandPlaceholder :
                    country.description==="Japan"? japanPlaceholder :
                    NoImage
                  }
                  cardName={country.description}
                  itemId={country.id}
                  handleCardClick={handleCardClick}
                >
                </CategoryCard>
              );
            })}
          </CardGridWrapper>
          :
          (isLoadingCountry ? "" : <NoDataFallback />)
        }
        </>
      }
      </CategoryLayout>
      <ResolutionDisclaimer/>
    </Wrapper>
  );
};
export default PrivateBrandCatalog;
