import { React, useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import DateSelector from "./DatePicker";
import MyListButton from "./MyListButton";
import PrintListButton from "./PrintListButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;

  @media print {
    display: none; 
  }
`;
const RouteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const HeadingSearchWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
`;
const RouteName = styled.h4`
  > a {
    color: #147350;
    text-decoration: none;
	  font-weight: 500;

    @media (max-width: 770px) {
      font-size: 0.8rem;
    }
  }
  > svg {
    font-size: 1.5rem;
    color: darkgray;
    position: relative;
    top: 0.43rem;
    margin: 0 0.5rem;

    @media (max-width: 1050px) {
      margin: 0 0.2rem;
    }
    @media (max-width: 770px) {
      font-size: 0.8rem;
      margin: 0 0.1rem;
      top: 0.2rem;
    }
  }
  .last-breadcrumb {
	color: #626262;
	text-decoration: none;
	pointer-events: none;
  }
`;

const SearchListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PBCHeader = (props) => {

  
  const headerLength= props.categoryDetail?.length ? props.categoryDetail.length : 0;
  const pageType = props.pageType;

  return (
    <Wrapper>
      <RouteWrapper>
        <RouteName>
          <NavLink to="/">Home</NavLink>
          <FiChevronRight />
        </RouteName>
        <RouteName>
          <NavLink 
		  	to="/privateBrandCatalog" 
			className={pageType === 'CountryList' ? 'last-breadcrumb' : ''}
		  >
			Private Brand Catalog
		  </NavLink>
          <FiChevronRight />
        </RouteName>
        {props.categoryDetail?.[0] && (
          <RouteName key={`${props.categoryDetail?.[0]}`}>
            <NavLink
              to={
                props.categoryDetail.length > 1
                ? `/privateBrandCatalog/${props.categoryDetail?.[0]}`
                : ""
              }
              // onClick={() => {
              //   props.handleSeeCategory(props.categoryDetail?.[0]);
              // }}
			  className={pageType === 'PsaList' || pageType === 'MyList' ? 'last-breadcrumb' : ''}
            >
              {props.categoryDetail?.[0]}
            </NavLink>
            <FiChevronRight />
          </RouteName>
        )}
        {props.categoryDetail?.[1] && (
          <RouteName key={`${props.categoryDetail?.[1]}`}>
            <NavLink
              to={
                props.categoryDetail.length > 2 
                ? `/privateBrandCatalog/${props.categoryDetail?.[0]}/category/${encodeURIComponent(props.categoryDetail?.[1])}`
                : ""
              }
              onClick={() => {
                props.handleSeeCategory(props.categoryDetail?.[1]);
              }}
			  className={pageType === 'SubCatList' || pageType === 'SearchPage' ? 'last-breadcrumb' : ''}
            >
              {props.categoryDetail?.[1]}
            </NavLink>
            <FiChevronRight />
          </RouteName>
        )}
        {props.categoryDetail?.[2] && (
          <RouteName key={`${props.categoryDetail?.[2]}`}>
            <NavLink
              to={
                props.categoryDetail.length > 3
                  ? `/privateBrandCatalog/${props.categoryDetail?.[0]}/category/${encodeURIComponent(props.categoryDetail?.[1])}?subcat=${encodeURIComponent(props.categoryDetail?.[2])}`
                  : ""
              }
			  className={pageType === 'SubCat' ? 'last-breadcrumb' : ''}
            >
              {props.categoryDetail?.[2]}
            </NavLink>
            <FiChevronRight />
          </RouteName>
        )}
        {props.categoryDetail?.[3] && (
          <RouteName key={`${props.categoryDetail?.[3]}`}>
            <NavLink className={pageType === 'ProductDetail' ? 'last-breadcrumb' : ''}>{props.categoryDetail?.[3]}</NavLink>
            <FiChevronRight />
          </RouteName>
        )}
      </RouteWrapper>
      <HeadingSearchWrapper>
        <div>
          <h1>
            {props.categoryDetail?.[1]==="Search" && `${props.header}`}
            {props.categoryDetail?.[0]==="My List" && "My List"}
            {headerLength===0 && pageType === "CountryList"? "All Countries" : ""}
             { headerLength===1 && pageType === "PsaList" ? `All Categories (${props.categoryDetail?.[0]})` :
                headerLength>=2 && pageType !== "SearchPage"? props.categoryDetail?.[1] : ""
            }
            {/* {props.categoryDetail?.[0] ? props.categoryDetail?.[0] : "Available Country"} */}
          </h1>
        </div>
        {/* <div>
          <DateSelector />
        </div> */}
        {/* props.categoryDetail?.[0]==="My List" */ pageType === "MyList" ?
          <div>
            <PrintListButton myListProduct={props.myListProduct}/>
          </div> :
          <SearchListWrapper>
            <div>
              {pageType !== "CountryList" && <SearchBar categoryDetail={props.categoryDetail} pageType={pageType} subcatDetails={props?.subcatDetails} />}
            </div>
            <div>
              <MyListButton/>
            </div>
          </SearchListWrapper>}
      </HeadingSearchWrapper>
    </Wrapper>
  );
};
export default PBCHeader;
