import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LuSearch } from "react-icons/lu";
import styled from "styled-components";
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

const Wrapper = styled.div`
  padding: 0.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;
  cursor: pointer;

  .MuiButton-contained{
    color: white;
    background-color: #147350;
    border-radius: 2rem;

    :hover{
        color: white;
        background-color: #147350;  
    }

    @media (max-width: 770px) {
      font-size: 0.7rem;
    }
  }

  .MuiSvgIcon-root{
    @media (max-width: 770px) {
      width: 1.1rem;
    }
  }

  @media (max-width: 770px) {
    width: 8rem;
    padding: 0;
    padding-left: 0.7rem;
  }
`;

const MyListButton = ({categoryDetail}) => {
    const navigate = useNavigate();
    const {myList=[]} = useSelector(state => state.pbCatalog);
    
    const handleButtonClick = () => {
        navigate(`/privateBrandCatalog/my-list`);
    };

    const getTheListButton = () => {
      return (<Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PersonIcon />}
          onClick={()=>{handleButtonClick()}}
          disabled={!myList.length}
      >
        MY LIST ({myList.length})
      </Button>);
    }
  
    return (
    <Wrapper >
        {myList.length ? getTheListButton() : <>
          <Tooltip title={`No items added`} arrow>
            <span>
              {getTheListButton()}
            </span>
          </Tooltip>
        </>}
    </Wrapper>
  );
};
export default MyListButton;
