import { React, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { redirect_uri, login_domain, client_id, isLoginEnabled } from "../../../config/environment";
import { checkFeatureAccess } from "../../../Utils/CommonUtils";

const NavItems = (props) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contact-us`);
  };
  
  // const red_uri = "us-west-1_3HSi8LtrJ";
  // const client_id = process.env.REACT_APP_SICI;
  // const login_domain = process.env.REACT_APP_LOGIN_DOMAIN;
  const login_redirect = `https://${login_domain}/login?client_id=${client_id}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://${redirect_uri}/assets`;
  // const isLoginEnabled = process.env.REACT_APP_LOGIN_ENABLED;

  let mobNavItem;
  if (state) {
    mobNavItem = (
      <div className="mobile-navbar">
        <Link
          to="/about"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("header_about")}
        </Link>
        <Link
          to="/intbrand"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("international_franchise")}
        </Link>
        <Link
          to="/careers"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("header_careers")}
        </Link>
        <Link
          to="/esg"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          ESG
        </Link>
        <Link
          to="/contact-us"
          className="buttons"
          id="supContactModal"
          onClick={() => {
            props.hamClose();
          }}
        >
          {/* <SupplierInfo
            supHamClose={props.hamClose}
            supContactOpen={props.supContactOpen}
            supCheckContactOpen={props.supCheckContactOpen}
            contactOpen={props.contactOpen}
          /> */}
          <button className="contactUsBtn">Suppliers</button>
        </Link>
        {checkFeatureAccess("Private Brand Catalog","view") && <Link
          to="/privateBrandCatalog"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          Private Brand Catalog
        </Link>}
        <Link
          to="/assets/"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("Digital_Assets_Small")}
        </Link>
        {checkFeatureAccess("Private Brand Catalog","view") && <Link
          to="/userManagement"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          User Management
        </Link>}
        <Link
          to="/logout"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("Log_Out")}
        </Link>
        <Link
          to="/contact-us"
          className="contact-button"
          id="contactUsModal"
          onClick={() => {
            props.hamClose();
          }}
        >
          {/* <ContactUs
            hamClose={props.hamClose}
            contactOpen={props.contactOpen}
            checkContactOpen={props.checkContactOpen}
            supContactOpen={props.supContactOpen}
          /> */}
          <button className="contactUsBtn">{t("contact_us")}</button>
        </Link>
      </div>
    );
  } else {
    mobNavItem = (
      <div className="mobile-navbar">
        <Link
          to="/about"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("header_about")}
        </Link>
        <Link
          to="/intbrand"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("international_franchise")}
        </Link>
        <Link
          to="/careers"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("header_careers")}
        </Link>
        <Link
          to="/esg"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          ESG
        </Link>
        <Link
          to="/contact-us"
          className="buttons"
          id="supContactModal"
          onClick={() => {
            props.hamClose();
          }}
        >
          {/* <SupplierInfo
            supHamClose={props.hamClose}
            supContactOpen={props.supContactOpen}
            supCheckContactOpen={props.supCheckContactOpen}
            contactOpen={props.contactOpen}
          /> */}
          <button className="contactUsBtn">Suppliers</button>
        </Link>
        {isLoginEnabled === "true" && (
          <a href={login_redirect} className="buttons">
            {t("Login")}
          </a>
        )}
        <Link
          to="/contact-us"
          className="contact-button"
          id="contactUsModal"
          onClick={() => {
            props.hamClose();
          }}
        >
          {/* <ContactUs
            hamClose={props.hamClose}
            contactOpen={props.contactOpen}
            checkContactOpen={props.checkContactOpen}
            supContactOpen={props.supContactOpen}
          /> */}
          <button className="contactUsBtn">{t("contact_us")}</button>
        </Link>
      </div>
    );
  }
  return mobNavItem;
};

export default NavItems;
