import React from "react";
import PropTypes from "prop-types";
import "./CustomConfirmationPopup.scss"; 

const CustomConfirmationPopup = ({ isVisible, closeDialog, content, buttons }) => {
	if (!isVisible) {
		return null;
	}

	return (
		<div className="modalWrapper showModal" onClick={closeDialog}>
			<div
				className="modalBody"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div className="iconWrapper">
					<div className="confirmationContainer">
						<div className="confirmationText">
							<h2 className="confirmHeader">{ content.title }</h2>
							<p className="confirmMessage">{ content.message }</p>
							<p className="confirmMessage">{ content.additionalMsg }</p>
						</div>
						<div className="confirmationBtnContainer">
							{
								buttons.map(btn => (
									<button className={`${btn.variant} confirmBtn`} onClick={btn.onClick}>
										{btn.text}
									</button>
								))
							}
						</div>
					</div>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						style={{
						color: "#ffffff",
						position: "absolute",
						zIndex: "1",
						top: "-7px",
						right: "10px",
						}}
						xmlns="http://www.w3.org/2000/svg"
						onClick={closeDialog}
					>
						<circle
							cx="9"
							cy="9"
							r="8.75"
							fill="white"
							stroke="#D1D1D1"
							strokeWidth="0.5"
							style={{ cursor: "pointer" }}
						/>
						<path
							d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
							fill="#000000"
							style={{ cursor: "pointer" }}
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

CustomConfirmationPopup.propTypes = {
	isVisible: PropTypes.bool.isRequired
};

export default CustomConfirmationPopup;