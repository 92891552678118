import { React} from "react";
import styled from "styled-components";
import NoImage from "../PrivateBrandCatalog/assets/NoImage.png"

const CardWrapper = styled.div`
  max-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
const CategoryCardImg = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  padding: 3px;
  background-color: white;
`;
const CardImg = styled.img`
  width: 99.5%;
  height: 99.5%;
  object-fit: contain;
  margin: 3px;
  border-radius: 4px;
`;
const CategoryCardName = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.15px;
`;

const CategoryCard = (props) => {
    const {
      itemId,
      cardName,
      imgSrc,
    } = props;

    return (
        <CardWrapper 
            key={itemId}
            onClick={() => {
                props.handleCardClick(cardName,itemId);
            }}
        >
            <CategoryCardImg>
                {imgSrc?
                    <CardImg alt="card image" src={imgSrc}></CardImg> :
                    <CardImg alt="fallback image" src={NoImage}></CardImg>
                }
            </CategoryCardImg>
            <CategoryCardName>{cardName}</CategoryCardName>
        </CardWrapper>
      );
 }
 
 export default CategoryCard;