import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import "./Leaders.css";
import l1 from "../../assets/images/shinji_Abe.png";
import l2 from "../../assets/images/ken_Wakabayashi.png";
import TakeshiKojimaHeadshot from "../../assets/images/takeshi_Kojima.png";
import SamPesekHeadshot from "../../assets/images/sam_Pesek.png";
import LeonidVolovnikHeadshot from "../../assets/images/leonid_Volovnik.png";
import MahitoEchizenya from "../../assets/images/mahito_Echizenya.png";
import HiroOhta from "../../assets/images/Hiro_Ohta.png";
import NoriKawazu from "../../assets/images/nori_Kawazu.png";
import JimThomas from "../../assets/images/jim_Thomas.png";
import { BsLinkedin } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import LayoutComponent from "../Layout/LayoutComponent";
import { AiFillCloseCircle } from "react-icons/ai";
const Leader = () => {
  const { t } = useTranslation();

  const [modalIsOpen1, setModalOpen1] = useState(false);
  const [modalIsOpen2, setModalOpen2] = useState(false);
  const [modalIsOpen3, setModalOpen3] = useState(false);
  const [modalIsOpen4, setModalOpen4] = useState(false);
  const [modalIsOpen5, setModalOpen5] = useState(false);
  const [modalIsOpen6, setModalOpen6] = useState(false);
  const [modalIsOpen7, setModalOpen7] = useState(false);
  const [modalIsOpen8, setModalOpen8] = useState(false);
  const [modalIsOpen9, setModalOpen9] = useState(false);

  const openModal1 = () => {
    setModalOpen1(true);
  };
  const openModal2 = () => {
    setModalOpen2(true);
  };
  const openModal3 = () => {
    setModalOpen3(true);
  };
  const openModal4 = () => {
    setModalOpen4(true);
  };
  const openModal5 = () => {
    setModalOpen5(true);
  };
  const openModal6 = () => {
    setModalOpen6(true);
  };
  const openModal7 = () => {
    setModalOpen7(true);
  };
  const openModal8 = () => {
    setModalOpen8(true);
  };
  const openModal9 = () => {
    setModalOpen9(true);
  };

  const closeModal1 = () => {
    setModalOpen1(false);
  };

  const closeModal2 = () => {
    setModalOpen2(false);
  };
  const closeModal3 = () => {
    setModalOpen3(false);
  };
  const closeModal4 = () => {
    setModalOpen4(false);
  };
  const closeModal5 = () => {
    setModalOpen5(false);
  };
  const closeModal6 = () => {
    setModalOpen6(false);
  };
  const closeModal7 = () => {
    setModalOpen7(false);
  };
  const closeModal8 = () => {
    setModalOpen8(false);
  };
  const closeModal9 = () => {
    setModalOpen9(false);
  };
  Modal.setAppElement("#root");

  return (
    <div className="leadership">
      <div className="page-header">
        <h1>{t("who_we_are")}</h1>
      </div>
      <nav>
        <NavLink className="text-link" to="/about" end>
          {t("our_brand_story")}
        </NavLink>
        <NavLink className="text-link" to="/leadership">
          {t("our_leadership")}
        </NavLink>
      </nav>
      <div>
        {/* <div className="leadership-list">
          <div className="leadership-item">
            <div className="leadership-item-container">
              <div className="leadership-detail-image">
                <img src={l1} height="250px" width="200px" />
                <div className="position">
                  <p className="leader_name">{t("ceo_shinji")}</p>
                  <p className="leader_title">{t("chairman")}</p>
                  <div className="leader_detail_btn">
                    <button className="openModalBtn" onClick={openModal2}>
                      {t("view_profile")}
                    </button>
                  </div>
                </div>
                <Modal
                  className="modal"
                  isOpen={modalIsOpen2}
                  onRequestClose={closeModal2}
                >
                  <div className="modal-content">
                    <div className="titleBtnContainer">
                      <div className="titleCloseBtn">
                        <AiFillCloseCircle
                          className="btn-modal-close"
                          onClick={() => {
                            setModalOpen2(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="leadership-container">
                        <div className="container">
                          <div className="leadership-detail-image">
                            <img
                              className="leaderImage"
                              src={l1}
                              height="250px"
                              width="20px"
                            />
                          </div>
                          <div className="leadership-detail-bio">
                            <div>
                              <h2 className="leader_name">{t("ceo_shinji")}</h2>
                              <p className="leader_title">{t("chairman")}</p>
                              <div className="leadership-detail-resources">
                                <div className="leadership-detail-social"></div>
                                <div className="leadership-detail-downloads"></div>
                              </div>
                            </div>
                            <div className="descriptionContainer">
                              <p className="leadersDescription">
                                {t("shin_quote1")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote2")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote3")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote4")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote5")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="leadership-list"
          style={{ paddingBottom: "3rem", paddingTop: "3rem" }}
        >
          <div className="leadership-item-container first-profile">
              <div className="leadership-detail-image">
                <img src={l1} height="250px" width="200px" />
                <div className="position">
                  <p className="leader_name">{t("ceo_shinji")}</p>
                  <p className="leader_title">{t("chairman")}</p>
                  <div className="leader_detail_btn">
                    <button className="openModalBtn" onClick={openModal2}>
                      {t("view_profile")}
                    </button>
                  </div>
                </div>
                <Modal
                  className="modal"
                  isOpen={modalIsOpen2}
                  onRequestClose={closeModal2}
                >
                  <div className="modal-content">
                    <div className="titleBtnContainer">
                      <div className="titleCloseBtn">
                        <AiFillCloseCircle
                          className="btn-modal-close"
                          onClick={() => {
                            setModalOpen2(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="leadership-container">
                        <div className="container">
                          <div className="leadership-detail-image">
                            <img
                              className="leaderImage"
                              src={l1}
                              height="250px"
                              width="20px"
                            />
                          </div>
                          <div className="leadership-detail-bio">
                            <div>
                              <h2 className="leader_name">{t("ceo_shinji")}</h2>
                              <p className="leader_title">{t("chairman")}</p>
                              <div className="leadership-detail-resources">
                                <div className="leadership-detail-social"></div>
                                <div className="leadership-detail-downloads"></div>
                              </div>
                            </div>
                            <div className="descriptionContainer">
                              <p className="leadersDescription">
                                {t("shin_quote1")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote2")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote3")}
                              </p>
                              <p className="leadersDescription">
                                {t("shin_quote4")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          <div className="leadership-item-container">
              <div className="leadership-detail-image">
                <img src={l2} height="250px" width="200px" />
                <div className="position">
                  <p className="leader_name">{t("ceo_ken")}</p>
                  <p className="leader_title">{t("president_ceo")}</p>
                  <div className="leader_detail_btn">
                    <button className="openModalBtn" onClick={openModal1}>
                      {t("view_profile")}
                    </button>
                  </div>
                </div>
                <Modal
                  className="modal"
                  isOpen={modalIsOpen1}
                  onRequestClose={closeModal1}
                >
                  <div className="modal-content">
                    <div className="titleBtnContainer">
                      <div className="titleCloseBtn">
                        <AiFillCloseCircle
                          className="btn-modal-close"
                          onClick={() => {
                            setModalOpen1(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="leadership-container">
                        <div className="container">
                          <div className="leadership-detail-image">
                            <img className="leaderImage" src={l2} />
                          </div>
                          <div className="leadership-detail-bio">
                            <div>
                              <h2 className="leader_name">{t("ceo_ken")}</h2>
                              <p className="leader_title">{t("president_ceo")}</p>

                              <div className="leadership-detail-resources">
                                <div className="leadership-detail-social">
                                  <a
                                    className="social-link"
                                    target="_blank"
                                    href="https://www.linkedin.com/in/ken-wakabayashi-98b571189/"
                                  >
                                    {<BsLinkedin />}
                                  </a>
                                </div>
                                <div className="leadership-detail-downloads"></div>
                              </div>
                            </div>
                            <div className="descriptionContainer">
                              <p className="leadersDescription">
                                {t("ken_quote1")}
                              </p>
                              <p className="leadersDescription">
                                {t("ken_quote2")}
                              </p>
                              <p className="leadersDescription">
                                {t("ken_quote3")}
                              </p>
                              <p className="leadersDescription">
                                {t("ken_quote4")}
                              </p>
                              <p className="leadersDescription">
                                {t("ken_quote5")}
                              </p>
                              <p className="leadersDescription">
                                {t("ken_quote6")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
        </div>
        <LayoutComponent>
          <div className="leadership-list-2">
            <div className="leadership-item-2 last-row">
              <div className="leadership-item-container">
                <div className="leadership-detail-image">
                  <img src={LeonidVolovnikHeadshot} />
                  <div className="position">
                    <p className="leader_name">{t("leonid")}</p>
                    <p className="leader_title">{t("vp_cao")}</p>
                    <div className="leader_detail_btn">
                      <button className="openModalBtn" onClick={openModal5}>
                        {t("view_profile")}
                      </button>
                    </div>
                  </div>
                  <Modal
                    className="modal"
                    isOpen={modalIsOpen5}
                    onRequestClose={closeModal5}
                  >
                    <div className="modal-content">
                      <div className="titleBtnContainer">
                        <div className="titleCloseBtn">
                          <AiFillCloseCircle
                            className="btn-modal-close"
                            onClick={() => {
                              setModalOpen5(false);
                            }}
                          />
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="leadership-container">
                          <div className="container">
                            <div className="leadership-detail-image">
                              <img
                                className="leaderImage"
                                src={LeonidVolovnikHeadshot}
                                height="250px"
                                width="20px"
                              />
                            </div>
                            <div className="leadership-detail-bio">
                              <div>
                                <h2 className="leader_name">{t("leonid")}</h2>
                                <p className="leader_title">{t("vp_cao")}</p>
                                <div className="leadership-detail-resources">
                                  <div className="leadership-detail-social">
                                    <a
                                      target="_blank"
                                      href="https://www.linkedin.com/in/leonidv/"
                                      className="social-link"
                                    >
                                      {<BsLinkedin />}
                                    </a>
                                  </div>
                                  <div className="leadership-detail-downloads"></div>
                                </div>
                              </div>
                              <div className="descriptionContainer">
                                <p className="leadersDescription">
                                  {t("leonid_quote1")}
                                </p>
                                <p
                                  className="leadersDescription">
                                  {t("leonid_quote2")}
                                </p>
                                <p className="leadersDescription">
                                  {t("leonid_quote3")}
                                </p>
                                <p className="leadersDescription">
                                  {t("leonid_quote4")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="leadership-item-2 last-row">
              <div className="leadership-item-container">
                <div className="leadership-detail-image">
                  <img src={HiroOhta} />
                  <div className="position">
                    <p className="leader_name">{t("hiro")}</p>
                    <p className="leader_title">{t("vp_gbd")}</p>
                    <div className="leader_detail_btn">
                      <button className="openModalBtn" onClick={openModal8}>
                        {t("view_profile")}
                      </button>
                    </div>
                  </div>
                  <Modal
                    className="modal"
                    isOpen={modalIsOpen8}
                    onRequestClose={closeModal8}
                  >
                    <div className="modal-content">
                      <div className="titleBtnContainer">
                        <div className="titleCloseBtn">
                          <AiFillCloseCircle
                            className="btn-modal-close"
                            onClick={() => {
                              setModalOpen8(false);
                            }}
                          />
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="leadership-container">
                          <div className="container">
                            <div className="leadership-detail-image">
                              <img
                                className="leaderImage"
                                src={HiroOhta}
                                height="250px"
                                width="20px"
                              />
                            </div>
                            <div className="leadership-detail-bio">
                              <div>
                                <h2 className="leader_name">{t("hiro")}</h2>
                                <p className="leader_title">{t("vp_gbd")}</p>
                                <div className="leadership-detail-resources">
                                  <div className="leadership-detail-social">
                                    <a
                                      target="_blank"
                                      href="https://www.linkedin.com/in/hiro-ohta/"
                                      className="social-link"
                                    >
                                      {<BsLinkedin />}
                                    </a>
                                  </div>
                                  <div className="leadership-detail-downloads"></div>
                                </div>
                              </div>
                              <div className="descriptionContainer">
                                <p className="leadersDescription">
                                  {t("hiro_quote1")}
                                </p>
                                <p className="leadersDescription">
                                  {t("hiro_quote2")}
                                </p>
                                <p className="leadersDescription">
                                  {t("hiro_quote3")}
                                </p>
                                <p className="leadersDescription">
                                  {t("hiro_quote4")}
                                </p>
                                <p className="leadersDescription">
                                  {t("hiro_quote5")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="leadership-item-2 last-row">
              <div className="leadership-item-container">
                <div className="leadership-detail-image">
                  <img src={MahitoEchizenya} />
                  <div className="position">
                    <p className="leader_name">{t("mahito")}</p>
                    <p className="leader_title">{t("vp_orI")}</p>
                    <div className="leader_detail_btn">
                      <button className="openModalBtn" onClick={openModal7}>
                        {t("view_profile")}
                      </button>
                    </div>
                  </div>
                  <Modal
                    className="modal"
                    isOpen={modalIsOpen7}
                    onRequestClose={closeModal7}
                  >
                    <div className="modal-content">
                      <div className="titleBtnContainer">
                        <div className="titleCloseBtn">
                          <AiFillCloseCircle
                            className="btn-modal-close"
                            onClick={() => {
                              setModalOpen7(false);
                            }}
                          />
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="leadership-container">
                          <div className="container">
                            <div className="leadership-detail-image">
                              <img
                                className="leaderImage"
                                src={MahitoEchizenya}
                                height="250px"
                                width="20px"
                              />
                            </div>
                            <div className="leadership-detail-bio">
                              <div>
                                <h2 className="leader_name">{t("mahito")}</h2>
                                <p className="leader_title">{t("vp_orI")}</p>
                                <div className="leadership-detail-resources">
                                  <div className="leadership-detail-social"></div>
                                  <div className="leadership-detail-downloads"></div>
                                </div>
                              </div>
                              <div className="descriptionContainer">
                                <p className="leadersDescription">
                                  {t("mahito_quote1")}
                                </p>
                                <p className="leadersDescription">
                                  {t("mahito_quote2")}
                                </p>
                                <p className="leadersDescription">
                                  {t("mahito_quote3")}
                                </p>
                                <p className="leadersDescription">
                                  {t("mahito_quote4")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="leadership-item-2 last-row last-profile">
              <div className="leadership-item-container">
                <div className="leadership-detail-image">
                  <img src={TakeshiKojimaHeadshot} />
                  <div className="position">
                    <p className="leader_name">{t("takeshi")}</p>
                    <p className="leader_title">{t("vp_inops")}</p>
                    <div className="leader_detail_btn">
                      <button className="openModalBtn" onClick={openModal3}>
                        {t("view_profile")}
                      </button>
                    </div>
                  </div>
                  <Modal
                    className="modal"
                    isOpen={modalIsOpen3}
                    onRequestClose={closeModal3}
                  >
                    <div className="modal-content">
                      <div className="titleBtnContainer">
                        <div className="titleCloseBtn">
                          <AiFillCloseCircle
                            className="btn-modal-close"
                            onClick={() => {
                              setModalOpen3(false);
                            }}
                          />
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="leadership-container">
                          <div className="container">
                            <div className="leadership-detail-image">
                              <img
                                className="leaderImage"
                                src={TakeshiKojimaHeadshot}
                                height="250px"
                                width="20px"
                              />
                            </div>
                            <div className="leadership-detail-bio">
                              <div>
                                <h2 className="leader_name">{t("takeshi")}</h2>
                                <p className="leader_title">
                                  {t("vp_inops")}
                                </p>
                                <div className="leadership-detail-resources">
                                  <div className="leadership-detail-social">
                                    <a
                                      target="_blank"
                                      href="https://www.linkedin.com/in/takeshi-kojima-9b9b65153/"
                                      className="social-link"
                                    >
                                      {<BsLinkedin />}
                                    </a>
                                  </div>
                                  <div className="leadership-detail-downloads"></div>
                                </div>
                              </div>
                              <div className="descriptionContainer">
                              <p className="leadersDescription">
                                  {t("takeshi_quote1")}
                                </p>
                                <p className="leadersDescription">
                                  {t("takeshi_quote2")}
                                </p>
                                <p className="leadersDescription">
                                  {t("takeshi_quote3")}
                                </p>
                                <p className="leadersDescription">
                                  {t("takeshi_quote4")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </LayoutComponent>
      </div>
    </div>
  );
};
export default Leader;
