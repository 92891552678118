import DOMPurify from 'dompurify';
import validator from 'validator';
import { app_url } from '../config/environment';
import store from "../components/Assets/PrivateBrandCatalog/Store";

const resValidation = (res) => {
    if(validator.isJSON(JSON.stringify(res))) {
        return res;
    }
    else {
        return null;
    }
}

const sanitizeContentToRender = content => {
	// Configure DOMPurify to allow specific tags and attributes
	const config = {
		ADD_ATTR: ["target", "url"]
	};
	return DOMPurify.sanitize(content, config);
};

const encodeUtil = (item) => {
    const str= JSON.stringify(item);
    const encodedStr= encodeURIComponent(str);
    return encodedStr;
}

const checkFeatureAccess = (feature, access) => {
    const accessToken = localStorage.getItem("access_token");
    const checkExpiry = localStorage.getItem("expiry_time");

    if(!accessToken || !checkExpiry){
        return false;
    }

    const {userAccess, isAdmin} = getReduxStateBySlice('userManagement');

    return isAdmin || (userAccess && userAccess[feature] && userAccess[feature][access]);
};

const getReduxStateBySlice = (sliceName) => {
    const appState = store.getState();
    return appState[sliceName];
};

const checkExpiryAndRedirect = () => {
    let isTokenActive = true;
    const expTime = localStorage.getItem('expiry_time');
    if (Date.now() / 1000 >= expTime) {
        localStorage.removeItem('expiry_time');
        localStorage.removeItem('auth_time');
        localStorage.removeItem('username');
        window.location.replace(`https://${app_url}/logout`);
        isTokenActive = false;
    }
    return isTokenActive;
};
export { resValidation, sanitizeContentToRender, encodeUtil, checkFeatureAccess, getReduxStateBySlice, checkExpiryAndRedirect };