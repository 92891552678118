import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from '@mui/material/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Wrapper = styled.div`
  padding: 0.7rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f7fafc;
  position: relative;
  cursor: ${(props)=>(props.disable? "default": "pointer")};

  @media print {
    .item-card {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    button {
      display: none; 
    }
  }

  .MuiButton-contained{
    color: white;
    background-color: #147350;
    border-radius: 2rem;

    :hover{
        color: white;
        background-color: #147350;  
    }

    @media (max-width: 770px) {
      font-size: 0.7rem;
    }
  }

  .MuiSvgIcon-root{
    @media (max-width: 770px) {
      width: 1.1rem;
    }
  }

  @media (max-width: 770px) {
    width: 8rem;
    padding: 0rem;
  }
`;

const PrintListButton = ({myListProduct}) => {
    const navigate = useNavigate();
    
    const handleButtonClick = () => {
        window.print();
    };
  
    return (
    <Wrapper disable={!myListProduct} >
        <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<MailOutlineIcon />}
            disabled={!myListProduct}
            onClick={()=>{handleButtonClick()}}
        >
        PRINT
        </Button>
    </Wrapper>
  );
};
export default PrintListButton;
