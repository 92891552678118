import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import video from "../assets/videos/Home-page-render.mp4"
import image1 from "../assets/images/Picture 1.jpg"
import image2 from "../assets/images/Picture 3.png"
import Carousel from 'react-material-ui-carousel'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Paper } from '@mui/material'
import "./FullPageCarousel.css"
import styled from "styled-components";

const Wrapper = styled.div`
    .MuiIconButton-root {
        top: calc(50% + 19.5rem)!important;
    }
    .css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
        color: #147350;
    }
    .MuiIconButton-root {
        opacity: 0.6;
        background-color: transparent;
        /* background-color: #147350; */
    }
    .MuiIconButton-root:hover {
        background-color: none;
    }
    .css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
        color: #147350;
    }
    .css-hn784z {
        left: 15rem;
    }
    .css-1abc02a {
        right: 15rem;
    }
`;

const FullPageCarousel = (props) => {

    return (
        <Wrapper>
            <Carousel
                NextIcon={<></>}
                PrevIcon={<></>}
                indicators={true}
                sx={{
                    zIndex: '0',
                    alignContent: 'center',
                    width: '-webkit-fill-available',
                    height: `${window.innerHeight - 110}px`
                }}
            >
                {
                    props.contents.map((content, i) => {
                        if(content['content'].substr(content['content'].lastIndexOf('.')+1, content['content'].length -1) === 'jpg' ||
                            content['content'].substr(content['content'].lastIndexOf('.')+1, content['content'].length -1) === 'png'
                        )
                        {
                            return (
                                <div>
                                    <img 
                                    style={{
                                        width: '-webkit-fill-available',
                                        height: `${window.innerHeight - 110}px`,
                                        filter: content['headings'] ? 'brightness(.5)' : 'brightness(1)'
                                        }} 
                                        src={content['content']} alt={content['content']} 
                                    />
                                    <Paper
                                    sx={{
                                        position: 'absolute',
                                        color: 'white',
                                        bottom: '8rem',
                                        textAlign: 'center',
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                        top: '40%',
                                        left: '50%',
                                        transform: 'translate(-50%)'
                                    }}
                                    >
                                        <h2
                                            style={{
                                                fontSize: '2rem',
                                                textShadow: '3px 3px 6px black'
                                            }}
                                        >
                                            {content['headings']}
                                        </h2>
                                        <p>{content['description'][0]}</p>
                                        <p>{content['description'][1]}</p>
                                    </Paper>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div>
                                    <video 
                                        src={content['content']} 
                                        loop 
                                        autoPlay 
                                        muted
                                        style={{
                                            width: '-webkit-fill-available',
                                            height: `${window.innerHeight - 110}px`,
                                            filter:'brightness(.5)'
                                        }}
                                    />
                                    <Paper
                                    sx={{
                                        position: 'absolute',
                                        color: 'white',
                                        bottom: '8rem',
                                        textAlign: 'center',
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                        top: '40%',
                                        left: '50%',
                                        transform: 'translate(-50%)'
                                    }}
                                    >
                                        <h2
                                            style={{
                                                fontSize: '2rem',
                                                textShadow: '3px 3px 6px black'
                                            }}
                                        >
                                            {content['headings']}
                                        </h2>
                                        <p>{content['description'][0]}</p>
                                        <p>{content['description'][1]}</p>
                                    </Paper>
                                </div>
                            )
                        }
                    })
                }
            </Carousel>
        </Wrapper>
    )
}

export default FullPageCarousel;